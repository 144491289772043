<template>
  <div>
    <span
        v-if="row.calendarStatus"
        @click="onStatusDeactivate"
        class="badge badge-success pointer"
    >
      {{ $t('general.actions.activated') }}
    </span>
    <span
        v-else @click="onStatusClick"
        class="badge badge-light pointer"
    >
      {{ $t('general.actions.deactivated') }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      default: this
    }
  },
  methods: {
    onStatusClick () {
        this.row.calendarStatus = !this.row.calendarStatus;
        this.$emit('on:calendar-badge-update', this.row);
    },
    onStatusDeactivate () {
      if (this.row.playgrounds.length > 1){
        this.row.calendarStatus = !this.row.calendarStatus;
        this.$emit('on:calendar-badge-update', this.row);
      } else {
        this.$flash(this.$t('general.actions.error'), this.$t('views.playground.steps.step-2.calendar-delete-error'));
      }
    }
  }
}
</script>
